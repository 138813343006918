<template>
  <div style="position: relative" class="w-100 d-flex flex-column">
    <loader-spinner no-border-radius-in-overlay centered v-if="isShowOverPageSpinner"></loader-spinner>
    <div class="d-flex w-100 flex-column justify-content-end align-items-end mb-1">
      <WButton info sm
        v-if="!isDisable"
        @click.stop.prevent="addButtonClickHandler"
        :disabled="isDisable"
      >
        {{ $t('SNMP.add') }}
      </WButton>
    </div>
    <div>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>{{ $t('SNMP.SNMPUserGroups.groupName') }}</th>
            <th>{{ $t('SNMP.SNMPUserGroups.securityLevel') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="group in userGroups" :key="group.name" @click.stop.prevent="tableStringClickHandler(group)">
            <td>{{ group.name }}</td>
            <td>{{ getSecurityLevelsForDisplayWithI18n(group.security_level) }}</td>
          </tr>
          <tr v-if="!userGroups.length">
            <td colspan="4" class="no-data-row">
              <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <modal
      v-if="isModalOpen"
      :backdrop="false"
      :title="
        isNew
          ? this.$t('SNMP.SNMPUserGroups.addUserGroupsModalHeader')
          : this.$t('SNMP.SNMPUserGroups.editUserGroupsModalHeader')
      "
      class="modal-info"
      closeBtn
      effect="fade/zoom"
      v-model="isModalOpen"
    >
      <h4 slot="modal-title" class="modal-title">
        {{
          isNew
            ? this.$t('SNMP.SNMPUserGroups.addUserGroupsModalHeader')
            : this.$t('SNMP.SNMPUserGroups.editUserGroupsModalHeader')
        }}
      </h4>
      <!--      name-->
      <div class="form-group">
        <label class="required-mark" for="snmp-user-groups-name">{{ $t('SNMP.SNMPUserGroups.groupName') }}:</label>
        <input
          data-vv-validate-on="none"
          @input="removeValidationErrorByName('snmp-user-groups-name')"
          :data-vv-as="$t('SNMP.SNMPUserGroups.groupName')"
          v-validate="'required'"
          type="text"
          :disabled="isDisable"
          v-model="updatedUserGroup.name"
          name="snmp-user-groups-name"
          id="snmp-user-groups-name"
          :placeholder="$t('SNMP.SNMPUserGroups.groupName')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-user-groups-name') }"
        />
        <span v-show="errors.has('snmp-user-groups-name')" class="help is-danger">
          {{ errors.first('snmp-user-groups-name') }}
        </span>
      </div>
      <!--     security-level -->
      <div class="form-group">
        <label class="required-mark" for="snmp-user-groups-security-level">
          {{ $t('SNMP.SNMPUserGroups.securityLevel') }}:
        </label>
        <select
          :data-vv-as="$t('SNMP.SNMPUserGroups.securityLevel')"
          v-validate="'required'"
          data-vv-validate-on="input"
          type="text"
          :disabled="isDisable"
          v-model="updatedUserGroup.security_level"
          name="snmp-user-groups-security-level"
          id="snmp-user-groups-security-level"
          :placeholder="$t('SNMP.SNMPUserGroups.securityLevel')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-user-groups-security-level') }"
        >
          <option :key="level" v-for="level in Object.values(securityLevels)" :value="level">
            {{ getSecurityLevelsForDisplayWithI18n(level) }}
          </option>
        </select>
        <span v-show="errors.has('snmp-user-groups-security-level')" class="help is-danger">
          {{ errors.first('snmp-user-groups-security-level') }}
        </span>
      </div>

      <!--     read View -->
      <div class="form-group">
        <label for="snmp-user-groups-security-read-view">{{ $t('SNMP.SNMPUserGroups.readView') }}:</label>
        <select
          :data-vv-as="$t('SNMP.SNMPUserGroups.readView')"
          v-validate="''"
          data-vv-validate-on="input"
          type="text"
          :disabled="isDisable"
          v-model="updatedUserGroup.read_view"
          name="snmp-user-groups-security-read-view"
          id="snmp-user-groups-security-read-view"
          :placeholder="$t('SNMP.SNMPUserGroups.readView')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-user-groups-security-read-view') }"
        >
          <option :key="readView" v-for="readView in readViews" :value="readView">
            {{ readView }}
          </option>
        </select>
        <span v-show="errors.has('snmp-user-groups-security-read-view')" class="help is-danger">
          {{ errors.first('snmp-user-groups-security-read-view') }}
        </span>
      </div>

      <!--     write View -->
      <div class="form-group">
        <label for="snmp-user-groups-security-write-view">{{ $t('SNMP.SNMPUserGroups.writeView') }}:</label>
        <select
          :data-vv-as="$t('SNMP.SNMPUserGroups.writeView')"
          v-validate="''"
          data-vv-validate-on="input"
          type="text"
          :disabled="isDisable"
          v-model="updatedUserGroup.write_view"
          name="snmp-user-groups-security-write-view"
          id="snmp-user-groups-security-write-view"
          :placeholder="$t('SNMP.SNMPUserGroups.writeView')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-user-groups-security-write-view') }"
        >
          <option :key="writeView" v-for="writeView in writeViews" :value="writeView">
            {{ writeView }}
          </option>
        </select>
        <span v-show="errors.has('snmp-user-groups-security-write-view')" class="help is-danger">
          {{ errors.first('snmp-user-groups-security-write-view') }}
        </span>
      </div>

      <div slot="modal-footer" class="modal-footer justify-content-between">
        <WButton success outline
          customClass="mr-1"
          v-if="!isDisable"
          @click="modalSaveButtonHandler"
          :disabled="errors.any()"
        >
          {{ isNew ? $t('general.create') : $t('general.save') }}
        </WButton>
        <div>
          <WButton danger outline
            customClass="mr-2"
            v-if="!isNew && !isDisable"
            @click="isDeleteOpen = true"
          >
            {{ $t('general.delete') }}
          </WButton>
          <WButton secondary outline
            @click="modalCancelButtonHandler"
          >
            {{ $t('general.close') }}
          </WButton>
        </div>
      </div>
    </modal>

    <delete-dialog
      :is-open="isDeleteOpen"
      :handle-close="closeDeleteDialogHandler.bind(this)"
      :handle-delete="deleteDialogDeleteHandler.bind(this)"
      target-text=""
    />
  </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import DeleteDialog from '../../../../components/delete-dialog.vue';
import snmpUserGroupsService from '../../../../services/Administration/SNMP/snmpUserGroupsService';

const SECURITY_LEVELS = {
  NO_AUTH_NO_PRIV: "noAuthNoPriv",
  AUTH_NO_PRIV: "authNoPriv",
  AUTH_PRIV: "authPriv",
};
const READ_VIEWS = [];
const WRITE_VIEWS = [];
export default {
  name: 'SNMPUserGroups',
  components: { Modal, DeleteDialog },
  data() {
    return {
      sort_by: {
        field: 'name',
        order: 1
      },
      isDeleteOpen: false,
      isModalOpen: false,
      isNew: false,
      userGroups: [
      ],
      updatedUserGroup: {},
      newUserGroup: {
        name: '',
        security_level: SECURITY_LEVELS.NO_AUTH_NO_PRIV,
        read_view: '',
        write_view: ''
      }
    };
  },
  computed: {
    showAllSettingsOnSNMPPartitionPages() {
      // используется для задачи DF-77 чтобы временно скрыть нереализованные на бэке функции
      // в подразделах раздела SNMP
      return this.$store.state.showAllSettingsOnSNMPPartitionPages;
    },
    securityLevels() {
      return SECURITY_LEVELS;
    },
    readViews() {
      return READ_VIEWS;
    },
    writeViews() {
      return WRITE_VIEWS;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    isShowOverPageSpinner() {
      return this.$store.state.loadingSNMPUserGroupsRequestExecuting ||
        this.$store.state.updateSNMPUserGroupsRequestExecuting ||
        this.$store.state.createSNMPUserGroupsRequestExecuting ||
        this.$store.state.deleteSNMPUserGroupsRequestExecuting;
    }
  },
  methods: {
    getSecurityLevelsForDisplayWithI18n(securityLevel) {
      try {
        if (securityLevel === SECURITY_LEVELS.NO_AUTH_NO_PRIV) {
          return this.$t('SNMP.SNMPUserGroups.securityLevels.noAuthNoPriv')
        } else if (securityLevel === SECURITY_LEVELS.AUTH_NO_PRIV) {
          return this.$t('SNMP.SNMPUserGroups.securityLevels.authNoPriv')
        } else if (securityLevel === SECURITY_LEVELS.AUTH_PRIV) {
          return this.$t('SNMP.SNMPUserGroups.securityLevels.authPriv')
        }
        return securityLevel
      } catch (e) {
        return securityLevel
      }
    },
    removeValidationErrorByName(errorName) {
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has(errorName)) {
          this.errors.remove(errorName);
        }
      }
    },
    addButtonClickHandler() {
      this.updatedUserGroup = JSON.parse(JSON.stringify(this.newUserGroup));
      this.openNewModal();
    },
    tableStringClickHandler(group) {
      this.updatedUserGroup = JSON.parse(JSON.stringify(group));
      this.openEditModal();
    },
    clearUpdated() {
      this.updatedUserGroup = JSON.parse(JSON.stringify(this.newUserGroup));
    },
    closeModal() {
      this.isModalOpen = false;
    },
    openModal() {
      this.isModalOpen = true;
    },
    openNewModal() {
      this.isNew = true;
      this.openModal();
    },
    openEditModal() {
      this.isNew = false;
      this.openModal();
    },
    modalSaveButtonHandler() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            if (this.isNew) {
              // console.log('creating new');
              snmpUserGroupsService.create(this);
            } else {
              snmpUserGroupsService.update(this);
            }
            this.closeModal();
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    modalCancelButtonHandler() {
      this.clearUpdated();
      this.closeModal();
    },
    modalDeleteBtuttonHandler() {},
    closeDeleteDialogHandler() {
      this.isDeleteOpen = false;
    },
    deleteDialogDeleteHandler() {
      snmpUserGroupsService.delete(this)
      this.closeDeleteDialogHandler();
      this.closeModal();
    },
    getAll() {
      snmpUserGroupsService.getAll(this);
    }
  },
  created() {
    this.getAll()
  }
};
</script>

<style scoped></style>
